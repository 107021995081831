@import "../style.css";

blockquote {
    border-left: .5em solid var(--impressed);
    padding-left: .5em;
}

pre {
    background-color: var(--impressed);
    padding: 1em;
    line-height: 1.25em;
}

code {
    background-color: var(--impressed);
    color: var(--accent)
}

.md-container {
    width: 80%;
    overflow: auto;
    padding: 2em;
    line-height: 1.8em;
    text-align: left;
}

p {
    color: var(--soft-text);
}

strong {
    color: var(--text);
}