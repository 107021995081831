@import 'style.css';

#home-container{
    text-align: left;
    margin-left: var(--def-margin);
    margin-right: var(--def-margin);
}

.link {
    text-decoration: underline;
}

.emphasis {
    color: var(--accent);
}

.p-text {
    color: var(--soft-text);
    line-height: 1.8
}

#landing-message {
    width: calc(65em - 8em);
    margin: 4em;
}

@media (max-width:65em) { 
    #landing-message {
        width: 100%;
        margin : 0;
    }
}