@import 'style.css';

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.model-name {
    text-decoration: underline;
    display: inline-block;
    margin-top: .75em;
    margin-bottom: .75em;
    margin-right: .75em;
}

.model-table {
    text-align: left;
}

.content-container {
    text-align: left;
}

.table-container {
    display: inline-block;
    text-align: left;
    margin-left: var(--def-margin);
    margin-right: var(--def-margin);
}
