@import "./style.css";

#menu{
    text-align: left;
    padding: 1em;
    min-width: 25%;
    max-width: calc(max(25%, 30em));
    box-sizing: border-box;
}

.resizer {
    background-color: var(--bg);
    width: 5px;
    cursor: ew-resize;
}

.resizer:hover{
    background-color: var(--impressed);
}


.import-node {
    width: unset;
    font-family: 'Courier New', Courier, monospace;
    background-color: var(--impressed);
    color: var(--accent);
    border-width: 0px;
    padding: 10px;
    font-size: 12px;
}

.export-node {
    width: unset;
    font-family: 'Courier New', Courier, monospace;
    background-color: var(--impressed);
    color: var(--accent);
    font-size: 12px;
}

.sourced-node{
    width: unset;
    font-family: 'Courier New', Courier, monospace;
    color: var(--accent);
    font-size: 12px;
}

.regular-node {
    width: unset;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
}

.attr-row {
    background-color: var(--impressed);
    margin-bottom: 1em;
    padding: .25em;
    border-width: 0px;
}

.attr-name {
    color: var(--accent);
}

.attr-value {
    font-family:'Courier New', Courier, monospace;
}

.focused-node-control {
    background-color: var(--impressed);
    color: var(--text);
    padding: 1em;
    text-decoration: underline;
    cursor: pointer;
}

.details-close-button {
    text-decoration: underline;
    font-size: .75em;
    cursor: pointer;
    float: right;
}

.detail-description {
    color: var(--soft-text)
}