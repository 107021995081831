@import 'style.css';

.text-bar {
    outline: none;
    border-width: 0px;
    padding: 10px;
    font-family: var(--font);
    background-color: var(--impressed);
    text-decoration: none;
    font-size: 1em;
    color: var(--text);

    width: calc(100% - 2 * 10px);
    height: calc(100% - 2 * 10px);
}

.text-box {
    outline: none;
    border-width: 0px;
    padding: 10px;
    font-family: var(--font);
    background-color: var(--impressed);
    text-decoration: none;
    font-size: 1em;
    color: var(--text);
    width: calc(100% - 2 * 10px);
    height: calc(100% - 2 * 10px);
}

.button {
    background-color: var(--impressed);
    color: var(--text);
    font-family: var(--font);
    font-size: 1em;
    padding: 10px;
    cursor: pointer;
    outline: none;
    border-width: 0px;
}

.check-button {
    background-color: var(--impressed);
    color: var(--impressed);
    padding: .1em;
    padding-left: .2em;
    padding-right: .2em;
    font-size: .75em;
    display: inline-block;
    cursor: pointer;
}

.check-label {
    vertical-align: middle;
    cursor: pointer;
    padding-left: .5em;
    cursor: pointer;
}

.file-upload {
    display: none;
}

.file-upload-label {
    background-color: var(--impressed);
    padding: 10px;
    cursor: pointer;
    display: inline-block;
}

.chosen-filename {
    color: var(--accent);
    margin-top: 1em;
}

.file-upload-filenames {
    margin-top: 1em;
    margin-bottom: 1em;
}