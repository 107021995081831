@import 'style.css';

.nav-container {
    font-family: var(--font);
    font-size: 1em;
    color: var(--text);
    padding: 1em;
    padding-left: var(--def-margin);
    text-align: left;
    border-bottom: 1px solid var(--text);

    display: flex;
}

.navbar-logo-image {
    height: 2.25em;
    margin-right: 1em;

    vertical-align: middle;
}

.little-text {
    margin-left: 1em;
    margin-right: 1em;
    padding-top: .5em;
    vertical-align: middle;

    text-decoration: underline;
    text-decoration-color: var(--accent);

    font-size: 1.25em;

    display: inline-block;
}

.login-sensitive {
    display: inline;
}