:root {
    --bg: #2f2f2f;
    --text: #ffffff;
    --accent: #55b13e;
    --font: 'Ubuntu';
    --def-margin: 2em;
    --impressed: #1f1f1f;
    --soft-text: #cccccc;
}

@font-face {
    font-family: 'Ubuntu';
    src: url("resources/Ubuntu-R.ttf");
}

a {
    all: reset;
}
