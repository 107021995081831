@import 'style.css';

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

a {
  color: #f0f0f0;
}

body {
  background-color: var(--bg);
  color: var(--text);
}

.App {
  text-align: center;
  display: block;
}
