@import 'style.css';

#content-container {
    text-align: left;
    margin-left: var(--def-margin);
    margin-right: var(--def-margin);
}

.form-row {
    margin-bottom: 1em;
}

.tag-box {
    color: var(--accent);
    margin-bottom: 1em;
}

.tag-delete {
    background-color: var(--impressed);
    color: var(--accent);
    padding: .25em;
    padding-right: .5em;
    padding-left: .5em;
    font-size: .75em;
    cursor: pointer;
}

.upload-error-message {
    color: #ec2f2f;
}
