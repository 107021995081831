@import 'style.css';

body {
    background-color: var(--bg);
}

.model_name {
    display: inline-block;
    margin-right: .5em;
    font-size: 2em;
    background-color: var(--bg);
    font-weight: bold;
    margin-bottom: .5em;
}

.editable-model-name {
    background-color: var(--impressed);
}

.model_card {
    text-align: left;
    margin-left: var(--def-margin);
    margin-right: var(--def-margin);
    margin-top: var(--def-margin);
}

.author {
    color: var(--accent);
}

.download-block {
    margin-top: 1em;
}

.short_desc {
    margin-top: 1em;
}

.readme-header {
    margin-top: 3em;
    background-color: var(--impressed);
    display: inline-block;
    padding: .1em;
    color: var(--accent);
}

.canonical {
    background-color: var(--impressed);
    display: inline-block;
    padding: .1em;
    color: var(--accent);
    margin-bottom: 1em;
}

.tag-group {
    display: inline-block;
    margin-top: 1em;
    margin-right: 1em;
}

.tag-group-name {
    color: var(--accent);
    margin-bottom: .5em;
    margin-right: .2em;
}

.tag {
    background-color: var(--impressed);
    color: var(--text);
    margin-right: .5em;
    padding: .2em;
}

.owner-options {
    background-color: var(--impressed);
    padding: 1em;
}

.delete-option {
    text-decoration: underline;
    cursor: pointer;
}

.upload-button {
    background-color: var(--bg);
}

.model-name-status-text {
    margin-bottom: 1em;
}

#index-file-text-box {
    background-color: var(--bg);
    width: min(calc(100% - var(--def-margin) * 2), )
}

.file-box {
    color: var(--accent);
    margin-bottom: 1em;
}

.file-delete {
    background-color: var(--bg);
    color: var(--accent);
    padding: .25em;
    padding-right: .5em;
    padding-left: .5em;
    font-size: .75em;
    cursor: pointer;
}